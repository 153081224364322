import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class UrlService {
// baseUrl = "https://13.233.155.0:4017/v1/admin";
//   imageUrl = "https://13.233.155.0:4017";
  baseUrl = "https://demo.appdukaan.com:2088/v1/admin";
  imageUrl = "https://demo.appdukaan.com:2088";

  constructor() {}
  login = `${this.baseUrl}/login`;
}
